@import "Assets/Styles/colours.scss";

.order-replace {
    width: 100%;
}

.order-replace .modal-container .modal {
    margin: 0;
    top: 0;
    border-radius: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    background-color: transparent;

    .header {
        border-radius: 0;
        height: 3vh;
    }

    .body {
        padding: 5px;
        height: auto;
        background-color: $white;

        .button-container {
            margin: 0 -5px -5px -5px;
            width: 100vw;
            

            :first-of-type, :last-of-type {
                border-radius: 0;
            }
        }

        .note {
            color: #a2a1a1;
            text-align: center;
            font-size: 14px;
        }

        .main {
            padding: 5px 20px;

            div {
                margin: 16px 0;
            }

            .input-container input {
                width: 100%;
                margin-left: 0;
                padding: 9px 0;
            }
        }
        
        .staggered {
            background-color: #ecebebc1;
        }

        .normal, .staggered {
            margin: 0 -5px; 
        }
        
        .name {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            padding: 6px;
            font-weight: 500;
            color: $primary;
            font-size: 13px;

            .icon {
                border-radius: 50%;
                background-color: $secondary;
                height: 24px;
                width: 24px;
                margin-right: 20px;
    
                svg {
                    height: 13px;
                    width: 13px;
                    padding: 5.5px;
                }
            }
        }

        .input-container {
            width: auto;
            padding: 2px 0;

            input {
                background-color: $white;
                border: 1px solid #e8e8e8c1;
                border-radius: 0.25rem;
                width: 33px;
                margin-left: 36px;
            }

            span {
                font-weight: 500;
                color: $primary;
                font-size: 13px;
            }
        }
    }
}